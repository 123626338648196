import React, { useEffect, useRef, useState } from "react";
import { initConsentSdk } from "../utils/initConsentSdk";
import { IntakeFormPayload } from "../types/consent";
import Environments from "../environments";
import { Dialog, DialogType, Spinner, SpinnerSize } from "@fluentui/react";
import { ApiPath } from "../constants";
import { messages } from "../messages";
import { SubmissionRequestPayload } from "../types/types";
interface QuestionnaireContainerProps {
  onFormSubmitSuccess: (residency: string, accessRequestId: string) => void;
  onFormSubmitFailure: () => void;
}

export function QuestionnaireContainer({
  onFormSubmitSuccess,
  onFormSubmitFailure,
}: QuestionnaireContainerProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useFormSubmitEffect(
    containerRef,
    setIsLoading,
    onFormSubmitSuccess,
    onFormSubmitFailure
  );

  return (
    <>
      {isLoading && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            type: DialogType.largeHeader,
          }}
          modalProps={{
            isBlocking: true,
            styles: {},
          }}
        >
          <Spinner label={messages.submittingDsr} size={SpinnerSize.large} />
        </Dialog>
      )}
      <div
        id="QuestionnaireContainer"
        ref={containerRef}
        style={{ height: "100vh", margin: "0px 10%" }}
      ></div>
    </>
  );
}

function useFormSubmitEffect(
  containerRef: React.RefObject<HTMLDivElement>,
  setIsLoading: (isLoading: boolean) => void,
  onFormSubmitSuccess: (residency: string, accessRequestId: string) => void,
  onFormSubmitFailure: () => void
) {
  useEffect(() => {
    const updateFormElements = (
      update: (element: HTMLInputElement) => void
    ): void => {
      const formElements =
        containerRef.current?.querySelectorAll<HTMLInputElement>(
          "select, input, button"
        );
      if (formElements) {
        for (const elem of formElements) {
          update(elem);
        }
      }
    };
    const disableForm = () =>
      updateFormElements((element) => (element.disabled = true));
    const enableForm = () =>
      updateFormElements((element) => (element.disabled = false));

    const registerSubmitCallback = async () => {
      await initConsentSdk();
      return window.MSFTPrivacy!.dsr!.on!(
        "QuestionsFormSubmit",
        async (payload: IntakeFormPayload) => {
          setIsLoading(true);
          disableForm();
          const submissionUrl = getHomeTenantSubmissionServiceEndpoint();
          const FormPayload = createSubmissionPayload(payload);
          const correlationId = crypto.randomUUID();
          try {
            await fetch(submissionUrl + ApiPath.submission, {
              method: "POST",
              headers: {
                "x-ms-correlation-request-id": correlationId,
                "x-ms-captcha-response-token":
                  window.MSFTPrivacy!.dsr!.getValue!("captchaToken"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(FormPayload),
            })
              .then((_) => {
                if (_.status >= 400) {
                  onFormSubmitFailure();
                } else {
                  _.json().then((json) => {
                    if (json.accessRequestId) {
                      onFormSubmitSuccess(
                        payload.data.CountryOrRegion,
                        json.accessRequestId
                      );
                    } else {
                      onFormSubmitFailure();
                    }
                  });
                }
                enableForm();
              })
              .catch((_) => {
                onFormSubmitFailure();
                enableForm();
              });
          } finally {
            setIsLoading(false);
          }
        }
      );
    };

    const onSubmitCallbackIdPromise = registerSubmitCallback();
    return () => {
      onSubmitCallbackIdPromise.then((id) => {
        window.MSFTPrivacy!.dsr!.off!("QuestionsFormSubmit", id);
      });
    };
  }, [containerRef]);
}

function createSubmissionPayload(payload: IntakeFormPayload) {
  const params = new URLSearchParams(window.location.search);
  const mappingId = params.get("id")!;

  const layoutProperties = payload.metadata.model.layout.properties;
  const modelProperties = payload.metadata.model.properties;

  const submissionPayload: SubmissionRequestPayload = {
    mappingId: mappingId,
    captureType: "form_tenant",
    formPayload: {
      data: payload.data,
      metadata: {
        model: {
          layout: {
            layoutProperties: {
              logoUrl: layoutProperties.logoUrl,
              companyName: layoutProperties.companyName,
            },
          },
          properties: {
            companyPrivacyContact: modelProperties.companyPrivacyContact,
            companyPrivacyStatement: modelProperties.companyPrivacyStatement,
          },
        },
        questions: payload.metadata.questions.map((question) => {
          return {
            name: question.name,
            properties: {
              classifications:
                question.properties.classifiers?.[0]?.value ?? [],
            },
          };
        }),
      },
    },
  };
  return submissionPayload;
}

function getHomeTenantSubmissionServiceEndpoint() {
  const homeTenantRegion =
    window.MSFTPrivacy!.dsr.getPackageConfiguration().properties.accountMetadata
      .location;
  const environment = Environments.getEnvironmentConfig();
  const endpoint = environment["submissionEndPointMap"].get(homeTenantRegion)!;
  return endpoint;
}
