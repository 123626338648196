import { IEnvironment } from "./environment";
import { getAzureEnvironmentConfig } from "./azure";
import { getEnvironmentFromCustomDomain } from "../utils/helperApi";

export default class Environments {
  /**
   * Get the configuration settings as per current environment.
   *
   * @returns {IEnvironment} Environment Configuration
   */
  public static getEnvironmentConfig(): IEnvironment {
    const customDomain = window.location.hostname;
    const params = new URLSearchParams(window.location.search);
    const urlEnvironmentName = params.get("feature.environment");

    const environmentName =
      urlEnvironmentName ?? getEnvironmentFromCustomDomain(customDomain);

    return getAzureEnvironmentConfig(environmentName!);
  }
}
