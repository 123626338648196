export const ApiPath = {
  submission: "/accessRequests?otpValidationEnabled=true",
  validateOtp: "/accessRequests/{0}:validateOtp",
  resendOtp: "/accessRequests/{0}:resendOtp",
};

export enum OTPValidationState {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  PIN_ERROR = "PIN_ERROR",
  RESEND_SUCCESS = "RESEND_SUCCESS",
  RESEND_ERROR = "RESEND_ERROR",
}

export const OTP_RESEND_TIMER_SECONDS = 120;

export const PROD_DOMAIN = "dsr.privacy.purview-service.microsoft.com";
export const CANARY_DOMAIN = "canary.dsr.privacy.purview-service.microsoft.com";
export const DOGFOOD_DOMAIN = "ci.dsr.privacy.purview-service.azure-test.com";
