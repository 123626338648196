import { CANARY_DOMAIN, DOGFOOD_DOMAIN, PROD_DOMAIN } from "../constants";

export const isProdDomain = (domain: string): boolean => {
  return domain == PROD_DOMAIN ? true : false;
};

export const isCanaryDomain = (domain: string): boolean => {
  return domain == CANARY_DOMAIN ? true : false;
};

export const isDogFoodDomain = (domain: string): boolean => {
  return domain == DOGFOOD_DOMAIN ? true : false;
};

export const getEnvironmentFromCustomDomain = (
  customDomain: string
): string | undefined => {
  return isProdDomain(customDomain)
    ? "production"
    : isCanaryDomain(customDomain)
    ? "canary"
    : isDogFoodDomain(customDomain)
    ? "dogfood"
    : undefined;
};

export const reTriggerChallenge = () => {
  // Disable the submit button and re-enable the checkbox to perform arkose challenge
  try {
    var submitButton = document.querySelectorAll(
      "button[type=submit]"
    )[0] as HTMLButtonElement;
    var checkBox = document.getElementById(
      "styled-checkbox"
    ) as HTMLInputElement;
    var checkBoxWrapper = document.getElementById(
      "captcha-checkbox"
    ) as HTMLDivElement;
    var checkMark = document.getElementById("checkmark") as HTMLDivElement;

    checkMark.style.display = "none";

    checkBox.style.display = "block";
    checkBox.checked = false;

    checkBoxWrapper.style.display = "block";
    submitButton.disabled = true;
  } catch (error) {}
};
