import "./App.css";
import React, { useState } from "react";
import OtpModal from "./components/OtpModal";
import { QuestionnaireContainer } from "./components/QuestionnaireContainer";
import { OTPValidationState } from "./constants";
import { initializeIcons } from "@fluentui/react";
import { reTriggerChallenge } from "./utils/helperApi";

initializeIcons();

export function App() {
  const [accessRequestId, setAccessRequestId] = useState<string>();
  const [residency, setResidency] = useState<string>();
  const [otpValidationState, setOtpValidationState] =
    useState<OTPValidationState>(OTPValidationState.NOT_STARTED);

  return (
    <>
      <QuestionnaireContainer
        onFormSubmitSuccess={(residency, accessRequestId) => {
          setAccessRequestId(accessRequestId);
          setResidency(residency);
          setOtpValidationState(OTPValidationState.IN_PROGRESS);
        }}
        onFormSubmitFailure={() => {
          document.getElementById("Questionnaire")!.hidden = true;
          window.MSFTPrivacy!.dsr!.trigger!("ShowTemplate", {
            templateId: "SubmissionFailure",
          });
        }}
      />
      {otpValidationState === OTPValidationState.IN_PROGRESS && (
        <OtpModal
          accessRequestId={accessRequestId}
          residency={residency}
          onOtpValidationSuccess={() => {
            setAccessRequestId(undefined);
            setOtpValidationState(OTPValidationState.NOT_STARTED);
            document.getElementById("Questionnaire")!.hidden = true;
            window.MSFTPrivacy!.dsr!.dataProps.push({
              dsarId: accessRequestId,
            });
            window.MSFTPrivacy!.dsr!.trigger!("ShowTemplate", {
              templateId: "SubmissionSuccess",
            });
          }}
          onCancel={() => {
            setAccessRequestId(undefined);
            setOtpValidationState(OTPValidationState.NOT_STARTED);
            reTriggerChallenge();
          }}
        />
      )}
    </>
  );
}

export default App;
