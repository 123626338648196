import Environments from "../environments";

let consentSdkInitPromise: Promise<void> | null = null;

export function initConsentSdk(): Promise<void> {
  // Return immediately if already initialized.
  if (consentSdkInitPromise != null) {
    return consentSdkInitPromise;
  }
  const params = new URLSearchParams(window.location.search);
  const mappingId = params.get("id");

  if (mappingId == null) {
    // TODO: Add graceful way to handle errors where required param is not provided.
    // Task 2421289: Error Handling for MappingId
    throw "mappingId is not provided as query param.";
  }

  const packageId = params.get("packageId")!;
  window.__MSFT_SDK = { config: { id: packageId } };
  const baseUrl = Environments!.getEnvironmentConfig()!.basePackageUrl;
  const arkosePublicKey = Environments!.getEnvironmentConfig()!.arkosePublicKey;

  addScripts(
    document,
    "script",
    [
      `packages/${packageId}/latest/core-config.js`,
      `packages/${packageId}/js/consent.min.js`,
    ],
    baseUrl
  );

  consentSdkInitPromise = new Promise<void>((resolve) => {
    window.MSFTConsent!.onLoad!(() => {
      window.MSFTPrivacy!.dsr!.init!({
        componentParentMap: { "*": "QuestionnaireContainer" },
        renderTemplateWithoutIframe: true,
        assetsPath: baseUrl,
        dataProps: {
          captcha: {
            isEnabled: true,
            siteKey: arkosePublicKey
          },
        },
      });
      window.MSFTPrivacy!.dsr!.on!("DSRSubmissionPageRedirect", () => {
        window.location.reload();
      });
      resolve();
    });
  }).catch((err) => {
    // TODO: Need to throw error output message to user.
    // Task 2421284: Error Handling for consent init failures
    alert(err);
  });

  return consentSdkInitPromise;
}

const addScripts = (
  document: Document,
  scriptTag: string,
  scripts: Array<string>,
  hostUrl = "."
) => {
  let isLoaded = false;
  const callbacks: Array<() => void> = [];

  window.MSFTConsent = {
    onLoad: (fn) => (isLoaded ? fn() : callbacks.push(fn)),
  };
  let currentScriptIdx = 0;

  const parentElement = document.getElementsByTagName(scriptTag)[0].parentNode!;

  const addScript = (src: string) => {
    const script = document.createElement(scriptTag);

    (script as HTMLScriptElement).src = `${hostUrl}${
      /[\\/]$/.test(hostUrl) ? "" : "/"
    }msft-privacy/${src}`;

    parentElement.insertBefore(script, parentElement.firstChild);

    script.onload = () => {
      currentScriptIdx++;

      if (currentScriptIdx < scripts.length)
        addScript(scripts[currentScriptIdx]);
      else {
        callbacks.forEach((fn) => fn());

        isLoaded = true;
      }
    };
  };

  addScript(scripts[0]);
};
