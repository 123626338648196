import React from "react";
import { FallbackProps } from "react-error-boundary";

export const FallBackErrorPage: React.ComponentType<FallbackProps> = ({ error, resetErrorBoundary }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ textAlign: 'center' }}>
          <h1 className="increase">Oops! Something went wrong</h1>
          <div>There was a problem processing the request. Please try again.</div>
          {/* <div>{error.message}</div> */}
          <div style={{ margin: '1rem 0' }}></div>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      </div>
    );
};