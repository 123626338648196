import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import Environments from "../environments";
import {
  ApiPath,
  OTPValidationState,
  OTP_RESEND_TIMER_SECONDS,
} from "../constants";
import { messages } from "../messages";
export interface OtpModalProps {
  residency: string | undefined;
  accessRequestId: string | undefined;
  onOtpValidationSuccess: () => void;
  onCancel: () => void;
}

export default function OtpModal(props: OtpModalProps) {
  const { residency, accessRequestId, onOtpValidationSuccess, onCancel } =
    props;
  let clearTimerRef = useRef<NodeJS.Timer>();

  // #region state and derived variables
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string | null>(null);
  const [otpValidationState, setOtpValidationState] =
    useState<OTPValidationState>(OTPValidationState.IN_PROGRESS);
  const [error, setError] = useState<string>("");
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [timeLeftForResending, setTimeLeftForResending] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [resentTime, setResentTime] = useState<number>();

  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: messages.identityValidation.title,
      subText: messages.identityValidation.description,
    }),
    [otpValidationState]
  );
  // #endregion

  // #region event handlers
  const submitOtp = async () => {
    setIsLoading(true);
    const endpoint = getHomeTenantSubmissionServiceEndpoint() + ApiPath.validateOtp;
    const url = endpoint.replace("{0}", accessRequestId as string);
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...createPayload(), otp }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((response) => {
              if (response.isValid) {
                onOtpValidationSuccess();
                setOtpValidationState(OTPValidationState.NOT_STARTED);
                return;
              }
            });
          } else {
            response.json().then((response) => {
              if (!response.isValid) {
                setError(response.errorMessage);
              }
              if (response.error) {
                setError(response.error.message);
              }
            });
            setOtpValidationState(OTPValidationState.PIN_ERROR);
          }
        })
        .catch(() => {
          setOtpValidationState(OTPValidationState.PIN_ERROR);
        });
    } catch (e) {
      setOtpValidationState(OTPValidationState.PIN_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTimeLeft = useCallback(() => {
    const now = new Date().getTime();
    const difference =
      OTP_RESEND_TIMER_SECONDS - (now - (resentTime ?? 0)) / 1000;
    let timeLeft = {
      minutes: 0,
      seconds: 0,
    };
    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 60) % 60),
        seconds: Math.floor(difference % 60),
      };
    }
    return timeLeft;
  }, [resentTime]);

  useEffect(() => {
    if (resentTime) {
      setTimeLeftForResending(calculateTimeLeft());
      clearTimerRef.current = setInterval(() => {
        setTimeLeftForResending(calculateTimeLeft());
      }, 1000);
    } else {
      clearInterval(clearTimerRef.current);
    }

    return () => {
      clearInterval(clearTimerRef.current);
    };
  }, [resentTime]);

  const resendOtp = async () => {
    setIsLoading(true);
    setResendDisabled(true);
    const endpoint = getHomeTenantSubmissionServiceEndpoint() + ApiPath.resendOtp;
    const url = endpoint.replace("{0}", accessRequestId as string);

    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...createPayload() }),
      })
        .then((response) => {
          const now = new Date().getTime();
          setResentTime(now);

          if (response.status === 200) {
            setOtpValidationState(OTPValidationState.RESEND_SUCCESS);
            return;
          }
          setOtpValidationState(OTPValidationState.RESEND_ERROR);
        })
        .catch(() => {
          setOtpValidationState(OTPValidationState.RESEND_ERROR);
        });
    } catch (e) {
      setOtpValidationState(OTPValidationState.RESEND_ERROR);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setResentTime(undefined);
        setResendDisabled(false);
      }, OTP_RESEND_TIMER_SECONDS * 1000);
    }
  };

  const isValidOtpInput = useMemo((): Boolean => {
    if (
      otp &&
      otp.length === 6 &&
      Number.isInteger(Number(otp)) &&
      Number(otp) >= 0
    ) {
      return true;
    }
    return false;
  }, [otp]);

  // #endregion

  function createPayload() {
    const params = new URLSearchParams(window.location.search);
    const mappingId = params.get("id")!;

    return {
      mappingId: mappingId
    };
  }

  function getHomeTenantSubmissionServiceEndpoint() {
    const homeTenantRegion = window.MSFTPrivacy!.dsr.getPackageConfiguration().properties.accountMetadata.location;
    const environment = Environments.getEnvironmentConfig();
    const endpoint = environment["submissionEndPointMap"].get(homeTenantRegion)!;
    return endpoint;
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={() => {}}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
        topOffsetFixed: true,
        overlay: { allowTouchBodyScroll: true },
      }}
    >
      {otpValidationState !== OTPValidationState.NOT_STARTED && (
        <>
          <div>
            <TextField
              label={messages.identityValidation.otpLabel}
              placeholder={messages.identityValidation.otpPlaceholder}
              onChange={(_, val) => setOtp(val as string)}
              errorMessage={
                otpValidationState === OTPValidationState.PIN_ERROR
                  ? error
                    ? error
                    : messages.identityValidation.incorrectPinLabel
                  : ""
              }
              type="number"
              min={0}
              max={999999}
              required
            />
            <div
              style={{
                display: "flex",
                alignSelf: "end",
                marginTop: "10px",
              }}
            >
              {isLoading && (
                <Spinner style={{ margin: "8px" }} size={SpinnerSize.small} />
              )}
            </div>

            <span>
              {timeLeftForResending?.minutes ||
              timeLeftForResending?.seconds ? (
                <>
                  <span>
                    {timeLeftForResending.minutes
                      ? timeLeftForResending.minutes.toString().padStart(2, "0")
                      : "00"}
                  </span>
                  <span>:</span>
                  <span>
                    {timeLeftForResending.seconds
                      ? timeLeftForResending.seconds.toString().padStart(2, "0")
                      : "00"}
                  </span>
                  {messages.identityValidation.toResend}
                </>
              ) : (
                messages.identityValidation.havingTrouble
              )}

              <PrimaryButton
                style={{
                  color: "#0078d4",
                  background: "#fff",
                  border: "none",
                }}
                disabled={isLoading || resendDisabled}
                onClick={() => resendOtp()}
              >
                {messages.identityValidation.resentOtpButton}
              </PrimaryButton>
            </span>
          </div>

          {otpValidationState === OTPValidationState.RESEND_SUCCESS && (
            <>
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >
                {messages.identityValidation.otpResent}
              </MessageBar>
            </>
          )}

          {otpValidationState === OTPValidationState.RESEND_ERROR && (
            <>
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {messages.identityValidation.otpResentError}
              </MessageBar>
            </>
          )}
        </>
      )}

      <DialogFooter>
        <DefaultButton onClick={onCancel}>
          {messages.identityValidation.cancelButton}
        </DefaultButton>

        <PrimaryButton
          disabled={!isValidOtpInput || isLoading}
          onClick={() => submitOtp()}
        >
          {messages.identityValidation.submitButton}
        </PrimaryButton>
      </DialogFooter>
    </Dialog>
  );
}
