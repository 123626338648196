export const messages = {
  identityValidation: {
    title: "Identity Validation",
    description:
      "A one-time pin has been sent to your email. Please enter it below to confirm your identity.",
    otpLabel: "One-time pin (OTP) ",
    otpPlaceholder: "Enter OTP",
    submitButton: "Submit",
    cancelButton: "Cancel",
    incorrectPinLabel: "Incorrect pin",
    havingTrouble: "Having trouble?",
    toResend: " to resend",
    resentOtpButton: "Resend OTP",
    otpResent: "OTP resent successfully",
    otpResentError: "OTP resend failed. Please try again later.",
    dsrSubmittedTitle: "Data Subject request submitted",
    dsrSubmittedDescription:
      "Your identity was successfully validated. Your request has been submitted successfully.",
    okButton: "Ok",
  },
  dsrFailed: "Your access request failed. Please try again later.",
  submittingDsr: "Submitting your request.",
  dsrSubmittedTitle: "Data subject request submitted",
  dsrSubmittedDescription:
    "Your identity was successfully validated and your request has been submitted to companyName. You can expect further communication via email. Processing time can vary per case, but typically take 3-4 weeks.",
  dsrFailedTitle: "Data subject request failed",
  dsrFailedDescription:
    "Your data subject request was not submitted. Please try again later.",
  backToHomeButton: "Go back to home page",
};
