import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { initConsentSdk } from "./utils/initConsentSdk";
import { ErrorBoundary } from "react-error-boundary";
import { FallBackErrorPage } from "./components/FallBackErrorPage";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const handleError = (error: Error, info: { componentStack: string }) => {
  // TODO (vithiyam): Finalize logging infra and log the error or
  // send it to an error tracking service.
};

initConsentSdk();

root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={FallBackErrorPage}
      onError={handleError}
    >
      <App></App>
    </ErrorBoundary>
  </React.StrictMode>
);
