import { IEnvironment } from "./environment";

export function getAzureEnvironmentConfig(environment: string): IEnvironment {
  const environments = [
    {
      name: "dogfood",

      basePackageUrl: "https://portalpkgppeeus2.z20.web.core.windows.net",

      submissionEndPointMap: new Map<string, string>([
        [
          "westus2",
          "https://westus2.api.purview.microsoft-ppe.com/privacy/dsr/submission/external",
        ],
        [
          "westus",
          "https://westus2.api.purview.microsoft-ppe.com/privacy/dsr/submission/external",
        ],
      ]),

      arkosePublicKey: "145565D5-7241-4420-9381-E758EBBAABE2",
    },
    {
      name: "canary",

      basePackageUrl: "https://portalpkgprodeus2.z20.web.core.windows.net",

      submissionEndPointMap: new Map<string, string>([
        [
          "eastus2euap",
          "https://eastus2euap.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
      ]),

      arkosePublicKey: "84A7CEB0-F479-4B7B-A525-58C7883A471B",
    },
    {
      name: "production",

      basePackageUrl: "https://portalpkgprodeus2.z20.web.core.windows.net",

      submissionEndPointMap: new Map<string, string>([
        [
          "uksouth",
          "https://uksouth.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "westeurope",
          "https://westeurope.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "southcentralus",
          "https://southcentralus.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "eastus2",
          "https://eastus2.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "northeurope",
          "https://northeurope.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "canadacentral",
          "https://canadacentral.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "westus3",
          "https://westus3.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
        [
          "eastus",
          "https://eastus.api.purview-service.microsoft.com/privacy/dsr/submission/external",
        ],
      ]),

      arkosePublicKey: "84A7CEB0-F479-4B7B-A525-58C7883A471B",
    },
  ];

  return environments.find((e) => e.name === environment) as IEnvironment;
}
